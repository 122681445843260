.service-info-card{
    padding: 1%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 10px;
    border-bottom: 0.3px solid black;
    align-items: center;
    padding-bottom: 3%;
}

.service-img{
    height: 70px;
    width: 70px;
}

.service-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.sub-info-service{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.rating{
    background-color: rgb(255,77,77);
    border-radius: 20px;
    color: white;
    width: 50px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
}
.rating>p{
    margin:auto
}