.App {
  text-align: center;
  height: 100vh;
  margin: auto;
  -webkit-tap-highlight-color: transparent;

}

.header{
  background-color: rgb(255, 77, 77);
  height: 130px;
  border-radius: 0px 0px 20px 20px;
}

.title{
  margin: 0;
  color: white;
  margin-top: 20px;
  font-family: reachmayy;
}

.header-content{
  padding: 20px;
}

.header-content>div>input{
  width: 100%;
  max-width: 600px;
  height: 30px;
  box-sizing:border-box;
  padding: 20px;
  border-radius: 20px;
  border-top: 0px solid rgb(255, 77, 77);
  border-left: 3px solid rgb(255, 77, 77);;
  border-right: 3px solid rgb(255, 77, 77);;
  border-bottom: 3px solid rgb(255, 77, 77);;
  font-size:0.9rem;
  font-family: cardtite;
}

.main-content{
  max-width: 800px;
  padding: 20px;
  margin: auto; 
  margin-top: 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  justify-items: center;
}


input[type=text]:focus {
  outline: none !important;
}

@font-face {
  font-family: reachmayy;
  src: url("./fonts/Pacifico-Regular.ttf");
}
@font-face {
  font-family: cardtite;
  src: url("./fonts/Quicksand-VariableFont_wght.ttf");
}

@media screen and (max-width:600px) {

  .main-content{
    margin-top: 10%;
  }
  
}