.slider-main{
    background-color: white;
    border-radius: 50px 50px 0px 0px;
    height: 90%;
    position: fixed;
    bottom: 0px;
    z-index: 5;
    width: 100%;
    box-shadow: 1px -10px 50px 5px  rgb(124, 82, 82);
    transition: transform 0.5s ;
    transform: translateY(200%);
}
@keyframes slider {
    0%{
        transform:translateY(-1200px)
    }
    100%{
        transform: translateY(12000px);
    }
}
.slide-content{
    max-width: 600px;
    margin: auto;
    padding: 2%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.slide-content::-webkit-scrollbar {
    display: none;
}

.slide-content>p{
    align-self: flex-end;
    font-weight: 900 ;
    cursor: pointer;
    margin-right: 20px;
}

.services-card{
    height: 100%;
    overflow-y: scroll;
    padding: 4%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-bottom: 10px;
}

.services-card::-webkit-scrollbar{
    display: none;
}

.action-btn{
    background-color: rgb(255,74,74);
    border-radius: 20px;
    padding: 2%;
    color: white;
    font-weight: 900;
    cursor: pointer;
    margin: auto;
    padding: 2% 10%;
    font-family: cardtite;
    margin-bottom: 10%;
}
