.search-Address>input,.user-form>input{
    width: 90%;
    padding: 2% 4%;
    height: 50px;
    border-radius: 50px;
    border:2px solid rgb(255,77,77);
    font-size: 1.2rem;
    font-family: cardtite;
}

.user-form>input{
  outline: none;
}

.user-form>input::-webkit-outer-spin-button,.user-form>input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.address-suggestion{
  position: fixed;
  height: 300px;
  z-index: 999;
  background-color: white;
  border:2px solid red;
  border-radius: 20px;
  width: 88%;
  margin-top: 10px;
  text-align:left;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 550px;
}
.address-suggestion>p{
  width: 100%;
  padding-left:4% ;
}

.address-selector-main{
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(192, 192, 192, 0.7);
  z-index: 9999999;
}

.address-selector-content{
  max-width: 600px;
  width: 100%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-family: cardtite;
  overflow-y: scroll;

}
.address-selector-content>div::-webkit-scrollbar,.address-selector-content::-webkit-scrollbar {
  display: none;
}
.address-input{

  display: flex;
  background-color: white;
}

.address-input>input{
  font-size: 1rem;
  border: 0px;
  width: 100%;
  font-family: cardtite;
}