.card {
    width: 100px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.5s;
    
  }
  
  .card:hover{
    transform: scale(1.2);
  }
  .box {
    width: 50px;
    height: 50px;
    background-color: white;
    margin: 0 auto;
    border-radius: 20px;
    border: 2px solid rgb(255,77,77);
    padding: 5.5%;
  }
  
  .title-card {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
    font-family: cardtite;
    font-weight: 500;
  }
  